/**
 * 本地化配置
 * 配置文字的本地化，包括语言、货币、图片的path等
 *
 */

export default {
  locationKey: "KE2",
  currencyCode: "KES",
  currencyName: "Kenyan Shilling",
  gameName: "GameLaka",
  appVersion: "1.1.1",
  forceUpdateToVersion: "1.1.1",
  companyName: "LAKA",
  licence: "No.0000356",
  availableCountryList: ["KE2"],
  icon: require("@/assets/icon.png").default,
  appIcon: require("@/assets/app_icon.png").default,
  appIcon64: require("@/assets/app_icon_64x64.png").default,
  appIconRound: require("@/assets/icon_round.png").default,
  appIconRound64: require("@/assets/icon_round_64x64.png").default,
  titleImg: require("@/assets/images/header/fz_char_logo.png").default,
  titleImgWhite: require("@/assets/images/header/fz_char_logo_white.png").default,
  indexBannerHarvestImg: require("@/assets/images/home/index_banner_harvest.png").default,
  indexBannerDepositImg: "index_banner_deposit",
  indexBannerDeposit2Img: "index_banner_deposit2",
  indexBannerDrawImg: "index_banner_draw",
  indexBannerinviteImg: "index_banner_invite",
  indexBannerLuckyWheelImg: "index_banner_lucky_wheel",
  indexBannerInstallImg: "index_banner_install",
  indexBannerFreeCashImg: "index_banner_freecash_1",
  referFriendsPopup: "ing_2_1",
  freeCashPopup: "popup_freecash_1",
  registerDownloadAd: "reg_download",
  downloadFloatImg: require("@/assets/images/download/float-download.png").default,
  luckyWheelShareImg: "/assets/images/lucky_wheel/banner_x2.png",
  indexBannerActivityImg: "index_banner_activity",

};
